<template>
  <section class="containerSearchBar">
    <section class="containerSearchBar__autocomplete">
      <section class="containerSearchBar__autocomplete--containerInput" :class="{filled: searchTerm}">
        <section class="input">
          <span class="grey-icon-search"></span>
          <input class="input__inputText" type="text" placeholder="Search People..." ref="inputSearch"
            v-model="searchTerm"
            @input="inputHandler($event)"
            @keydown.enter="applySearch"
            @keydown.up="up"
            @keydown.down="down" />
            <hr class="input__barAnimated">
          <span class="grey-icon-clear" @click="removeSearchTerm" v-if="searchTerm"></span>
          <div class="input__autoLine"></div>
          <section class="input__suggestionsList" @mouseleave="resetActiveOver">
            <transition-staggered tag="ul" className="input__suggestionsList--list"
              type="vertically" v-if="showSuggestions" ref="optionsList">
              <li v-for="(item, index) in matches" :key="index" :data-index="index"
                @mouseover="removeSelected(index)"
                @click="itemSelected(index)"
                class="suggestion"
                :class="{'selected': selected === index, 'activeHover': activeHover === index}"
                :title="item.name">
                {{item.name}}
              </li>
            </transition-staggered>
          </section>
        </section>
      </section>
      <button class="containerSearchBar__autocomplete--searchButton" @click="applySearch">
        <span class="label">Search</span>
        <span class="grey-icon-search"></span>
      </button>
    </section>
    <filterPillList :inSearchbar="true"
      :filterList="store.state.filters.selectedFilters"
      @clearFilter="filterSelectionHandler($event, false)"
      @clearAllFilters="clearAllFilters">
    </filterPillList>
    <misspellLink @apply-search="applySearchNewSpell"></misspellLink>
    <span class="containerSearchBar__searchResultsMsg" :class="{'visible': showLabel}" v-html="label" />
    <section class="containerSearchBar__actionButtons">
      <section class="containerSearchBar__actionButtons--left">
        <div v-if="showSelectAll && showActionButtons" class="checkBox">
          <genericCheckBox v-if="store.state.idsLoaded"
                   :indeterminate="isIndeterminate"
                   v-model="selectedAll"
                   class="checkBox__check md-primary"
                   label="Select All">
          </genericCheckBox>
          <spinner class="checkBox__spinner" v-else/>
        </div>
        <span class="counter" v-if="selectedUsers.length">({{selectedUsers.length}} User<span v-if="areManyUsersSelected">s</span> selected)</span>
        <span class="modalButton" v-if="selectedUsers.length" @click.stop="openCollectionsModal">
          <span class="grey-icon-add-to-collections modalButton__button"></span>
          <span class="modalButton__label">Add To Collection</span>
        </span>
      </section>
      <section class="containerSearchBar__actionButtons--right">
        <span
          v-if="showActionButtons && !store.state.isGridViewListView"
          @click.stop="toggleView"
          :class="['toggleView', isGridView ? 'grey-icon-list-view': 'grey-icon-grid-view']">
        </span>
        <button class="filterButton" v-if="showActionButtons" @click="openFilters">
          <span class="filterButton__label">Filters</span>
          <span class="grey-icon-filters"></span>
        </button>
      </section>
    </section>
    <section class="containerSearchBar__mobileModal" :style="{height: [selectedUsers.length ? '53px' : '0px']}">
      <span class="containerSearchBar__mobileModal--counter">{{selectedUsers.length}} User<span v-if="areManyUsersSelected">s</span> selected</span>
      <span class="containerSearchBar__mobileModal--separator">|</span>
      <span class="grey-icon-add-to-collections containerSearchBar__mobileModal--icon" @click="openCollectionsModal"></span>
      <span class="grey-icon-close containerSearchBar__mobileModal--closeButton" @click="clearSelectedUsers"></span>
    </section>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import filterPillList from '@/components/filters/filterPillList/filterPillList'
import misspellLink from '@/components/misspellLink/misspellLink'
import escapeCharacters from '../../utils/escapeCharacters'
import validateMaintenance from '../../utils/validateMaintenance'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import spinner from '@/components/spinner/spinner'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'searchBar',
  components: {
    filterPillList,
    misspellLink,
    spinner,
    genericCheckBox
  },
  props: {
    showLabel: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    isGridView: {
      type: Boolean,
      required: true
    },
    showActionButtons: {
      type: Boolean,
      required: true
    },
    showSelectAll: {
      type: Boolean,
      required: true
    },
    isIndeterminate: {
      type: Boolean,
      required: true
    },
    selectedUsers: {
      type: Array,
      default () {
        return []
      }
    },
    totalResultsCount: {
      type: Number,
      required: true
    }
  },
  emits: ['setUsersSelected', 'apply-filters', 'open-filters', 'toggle-view', 'input', 'openCollectionsModal'],
  directives: {

    /**
     * @description Autofucus on input
     * @param el Element DOM
     */
    focus: {
      inserted: function (el) {
        el.focus()
      }
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const isApplySearch = ref(null)
    const searchTerm = ref('')
    const requestControl = ref(null)
    const matches = ref([])
    const selected = ref(-1)
    const activeHover = ref(null)
    const inputSearch = ref(null)
    const intervalProgress = ref(null)
    const setSuggestions = ref(false)

    onMounted(() => {
      document.addEventListener('click', clearSuggestionOnBlur)
      searchTerm.value = store.state.modelSearchBox
      inputSearch.value.focus()
    })

    onUnmounted(() => {
      document.removeEventListener('click', clearSuggestionOnBlur)
    })

    /**
     * @description Flag to know if there are more than one card selected.
     */
    const areManyUsersSelected = computed(() => {
      return props.selectedUsers.length > 1
    })

    /**
     * @description Validate if display suggestions list.
     */
    const showSuggestions = computed(() => {
      return matches.value.length && searchTerm.value.length > 2
    })

    /**
     * @description Variable associated with select all checkbox
     */
    const selectedAll = computed({
      get: () => {
        return props.selectedUsers.length === props.totalResultsCount
      },
      set: (value) => {
        const usersSelected = value ? Object.assign([], store.state.selectedCards) : []
        emit('setUsersSelected', usersSelected)
      }
    })

    /**
     * @description Handler for any change on the input.
     * @param event DOM event
     */
    function inputHandler (event) {
      setSuggestions.value = true
      isApplySearch.value = false
      selected.value = selected.value === null ? -1 : selected.value
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: event.target.value,
        apply: false
      })
      if (searchTerm.value.length < 3) {
        matches.value = []
        selected.value = -1
      }
      searchMatch()
    }

    /**
     * @description Commits a change in the selection of a filter.
     * @param object {filter} filter changed.
     * @param boolean {isSelected} flag to know if the filter has been selected.
     */
    function filterSelectionHandler (filter, isSelected) {
      store.commit({
        type: 'filters/TOGGLE_FILTER_SELECTION',
        filter: filter,
        isSelected: isSelected
      })
      emit('apply-filters')
    }

    /**
     * @description Clears all the selected filters from the store and launch a new search.
     */
    function clearAllFilters () {
      store.commit({
        type: 'filters/CLEAR_ALL_FILTERS'
      })
      emit('apply-filters')
    }

    /**
     * @description Makes the search with the newSpell suggested
     */
    function applySearchNewSpell () {
      searchTerm.value = store.state.modelSearchBox
      applySearch(true)
    }

    /**
     * @description Make the search with the entered value
     */
    async function applySearch (correctionNewSpell = false) {
      finishAnimation()
      if (!correctionNewSpell) {
        store.commit({
          type: 'SET_CORRECTION_NEW_SPELL',
          correctionNewSpell: ''
        })
      }
      setSuggestions.value = false
      setSelectedOption()
      setGlobalVariables()
      initAnimation()

      let value = searchTerm.value
      value = value ? (store.state.correctionNewSpell ? `%22${searchTerm.value}%22` : searchTerm.value) : '*'
      value = escapeCharacters(value, 'Search')

      store.dispatch('setQuery', {
        query: value.replace(/["]+/g, ''),
        filters: store.getters['filters/getQueryFilters']()
      })
      store.dispatch({
        type: 'getSearchApiPeople',
        params: {
          ...store.state.query
        }
      }).then(response => {
        updateStore(response.data.value, response.data['@odata.count'])
        goToResults()
        finishAnimation()
        setValuesAfterSearch(response)
      }).catch(error => {
        validateMaintenance(error)
        console.error('[ERROR] sendSearchRequest ', error)
      })
    }

    /**
     * @description Set values according to selected option to search
     */
    function setSelectedOption () {
      inputSearch.value.blur()
      selected.value = selected.value === null ? -1 : selected.value
      isApplySearch.value = true

      store.commit({
        type: 'SET_INITIAL_OFFSET',
        initialOffset: 0
      })

      if (selected.value !== -1) {
        searchTerm.value = matches.value[selected.value].name
      }

      selected.value = -1
      matches.value = []
    }

    /**
     * @description Set values for global variable related with autocomplete search
     */
    function setGlobalVariables () {
      store.commit({
        type: 'SET_LOADING_APP',
        loading: true
      })
      store.commit({
        type: 'CLEAR_PROPERTIES'
      })
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTerm.value,
        apply: true
      })
      store.commit({
        type: 'SET_SCROLL_POSITION',
        scrollPosition: 0,
        appHeigth: 0,
        fromDetailView: false
      })
    }

    /**
     * @description Set values after search has been performed
     * @param results Array of items of the search
     */
    function setValuesAfterSearch (results) {
      setTimeout(() => {
        store.state.firstTime = false
      })
      store.state.newSearch = true
      store.commit({
        type: 'SET_NEW_SPELL',
        newSpell: results.data.correctedSearchTerm ? results.data.correctedSearchTerm : ''
      })

      store.commit({
        type: 'SET_NEW_SPELL_NUMBER',
        newSpellNumber: results.data.correctedResultNumber
      })

      store.commit({
        type: 'SET_ORIGINAL_RESULTS',
        originalResults: results.data.originalHasResults
      })
      store.state.clickSelectAllFirst = false

      if (!store.state.clickSelectAllFirst) {
        store.dispatch('saveIdsUsers')
      } else {
        store.commit({
          type: 'SET_LOADING_APP',
          loading: false
        })
      }
    }

    /**
     * @description Set variables according to the search results
     */
    function updateStore (results, resultsCount) {
      store.dispatch('setTotalResultsCount', resultsCount)
      store.commit({
        type: 'SET_RESULTS',
        results: results
      })
    }

    /**
     * @description Go to the results page with the search results
     */
    function goToResults () {
      store.commit({
        type: 'SET_ROUTE_CONFIG',
        searchTerm: store.state.correctionNewSpell ? `%22${searchTerm.value.trim()}%22` : searchTerm.value.trim(),
        pageNumber: 1
      })
      router.push(store.state.routeConfig)
      if (store.state.isMobile) window.scrollTo(0, 0) // Safari bottom bar fix
    }

    /**
     * @description Select item when user click
     * @param index Index from list
     */
    function itemSelected (index) {
      inputSearch.value.focus()
      selected.value = index
      applySearch()
    }

    /**
      * @description Get match results with typing's user
      */
    function searchMatch () {
      if (searchTerm.value.length > 2) {
        clearTimeout(requestControl.value)
        requestControl.value = setTimeout(() => {
          store.dispatch({
            type: 'predictiveSearch',
            searchTerm: searchTerm.value
          }).then(
            (response) => {
              if (setSuggestions.value) matches.value = response.data
            },
            (error) => {
              console.error(error)
            }
          )
        }, 500)
      }
    }

    /**
     * @description Event dispatch when user press up from keyboard
     */
    function up () {
      if (selected.value === -1) {
        return
      }
      selected.value = activeHover.value ? activeHover.value - 1 : selected.value - 1
      activeHover.value = null
    }

    /**
     * @description Event dispatch when user press down from keyboard
     */
    function down () {
      if (selected.value >= matches.value.length - 1) {
        return
      }
      selected.value = activeHover.value ? activeHover.value + 1 : selected.value + 1
      activeHover.value = null
    }

    /**
     * @description Event dispatch when user perform hover in any item from the list
     * @param index Index item that user is hovering
     */
    function removeSelected (index) {
      selected.value = null
      activeHover.value = index
    }

    /**
     *  @description Event to reset the active over property when mouse is located outside the suggestions container
     */
    function resetActiveOver () {
      selected.value = -1
      activeHover.value = null
    }

    /**
     * @description Set search term to empty
     */
    function removeSearchTerm () {
      searchTerm.value = ''
    }

    /**
     * @description Clear and hide the autocomplete suggestions when user clicks outside the searchbar
     */
    function clearSuggestionOnBlur (event) {
      if (event.target.closest('.containerAutocomplete') === null) {
        selected.value = -1
        activeHover.value = null
        matches.value = []
      }
    }

    /**
     * @description Emit event to open filters modal
     */
    function openFilters () {
      emit('open-filters')
    }

    /**
     * @description Emit event to toggle view mode
     */
    function toggleView () {
      emit('toggle-view')
    }

    /**
     * @description Rises an event to notify component has been checked or unchecked.
     */
    function updateValue () {
      emit('input', selected.value)
    }

    /**
     * @description Rises an event to open collections modal.
     */
    function openCollectionsModal () {
      store.dispatch({
        type: 'getCollections',
        ids: props.selectedUsers
      }).then(() => {
        emit('openCollectionsModal', props.selectedUsers, true)
      })
    }

    /**
     * @description Begins the animation of the bottom bar.
     */
    function initAnimation () {
      const styles = document.documentElement.style
      let progress = 1
      intervalProgress.value = setInterval(() => {
        progress *= 1.2
        styles.setProperty('--progressBarCounter', `${progress}%`)

        if (progress >= 99) {
          styles.setProperty('--progressBarCounter', '99%')
          clearInterval(intervalProgress.value)
        }
      }, 100)
    }

    /**
     * @description Finishes the animation of the bottom bar.
     */
    function finishAnimation () {
      clearInterval(intervalProgress.value)
      const styles = document.documentElement.style
      styles.setProperty('--progressBarCounter', '100%')
      setTimeout(() => {
        styles.setProperty('--progressBarCounter', '0%')
      }, 300)
    }

    /**
     * @description Clear selected users
     */
    function clearSelectedUsers () {
      emit('setUsersSelected', [])
    }

    return {
      store,
      router,
      inputSearch,
      areManyUsersSelected,
      selectedAll,
      filterSelectionHandler,
      clearAllFilters,
      applySearch,
      applySearchNewSpell,
      itemSelected,
      up,
      down,
      removeSelected,
      resetActiveOver,
      removeSearchTerm,
      openFilters,
      toggleView,
      updateValue,
      openCollectionsModal,
      searchTerm,
      matches,
      selected,
      activeHover,
      initAnimation,
      finishAnimation,
      clearSelectedUsers,
      inputHandler,
      showSuggestions
    }
  }
}
</script>
