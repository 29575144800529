import charList from './specialCharacters.json'

/**
 * @description Modify the giver uri escaping the special characters given.
 * @param str String to be modify
 * @param charSetName Name of the set of characters to be replaced
 */
export default (str, charSetName) => {
  const charSet = charList[charSetName]
  charSet.forEach(([char, replacement]) => {
    str = str.replace(new RegExp(char, 'g'), replacement)
  })
  return str
}
