<template>
  <section class="containerFilterPill">
    <span class="containerFilterPill__label">{{filterName}}</span>
    <span class="grey-icon-clear" @click="clearFilter()"></span>
  </section>
</template>

<script>
export default {
  name: 'filterPill',
  props: {
    filterName: {
      type: String,
      required: true
    }
  },
  emits: ['clearFilter'],
  setup (props, { emit }) {
    /**
     * @description Propagates event to notify the filter has been deleted.
     */
    function clearFilter () {
      emit('clearFilter')
    }

    return {
      clearFilter
    }
  }
}
</script>
