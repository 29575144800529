<template>
  <section class="containerCategories" v-if="!store.state.filters.isLoading">
    <transition-staggered tag="ul" type="vertically" class="containerCategories__list">
      <li v-for="(category, index) in store.state.filters.categoriesAsList"
          class="containerCategories__list--item"
          :key="category.label"
          :data-index="index">
        <filterCategoryTab
          :category="category"
          :isModalMobile="isModalMobile"
          @filterToggleSelection="filterSelectionHandler"
          :ref="setItemTabRef"
          :key="filterCategoryTabKey(index)"
        >
        </filterCategoryTab>
      </li>
    </transition-staggered>
  </section>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import filterCategoryTab from '../filterCategoryTab/filterCategoryTab'

export default {
  name: 'filterCategories',
  components: {
    filterCategoryTab
  },
  props: {
    isModalMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['filterToggleSelection'],
  setup (props, { emit }) {
    const store = useStore()
    const filterCategoriesTabRef = ref([])

    /**
     * @description add a new ref to refs array
     * @param el ref identifier to be added
     */
    const setItemTabRef = computed(() => (el) => {
      if (el) filterCategoriesTabRef.value.push(el)
    })

    /**
     * @description Set child component key identifier
     * @param index index of the element on the array
     */
    const filterCategoryTabKey = computed(() => (index) => {
      return `${index}-${store.state.filters.randomFilterCategoryTabKey}`
    })

    /**
     * @description Calculates the number of columns
     */
    function calculateNumberOfColumns () {
      const indexOpenedTab = store.state.filters.categoriesAsList.findIndex((category) => category.isSelected)
      if (indexOpenedTab !== -1) {
        filterCategoriesTabRef.value[indexOpenedTab].calculateNumberOfColumns()
      }
    }

    /**
      * @description Emits an event to notify a change in the selection of a filter.
      * @param object {filter} filter changed.
      * @param boolean {isSelected} flag to know if the filter has been selected.
      */
    function filterSelectionHandler (filter, isSelected) {
      emit('filterToggleSelection', filter, isSelected)
    }

    return {
      store,
      setItemTabRef,
      calculateNumberOfColumns,
      filterSelectionHandler,
      filterCategoryTabKey
    }
  }
}
</script>
