<template>
  <section :class="['containerResultsPage', store.state.isToolbarVertical ? 'resultPageToolbarVertical' : 'resultPageToolbarHorizontal']">
    <section class="containerResultsPage__title">
      <span
        class="containerResultsPage__title--label"
        :class="{'clickeableTitle' : router.currentRoute._value.name == 'resultsPage'}"
        @click="goHome()">
        PEOPLE OF GREY
      </span>
    </section>
    <search-bar
      :showLabel="totalResultsCount >= 0"
      :label="reySays"
      :isGridView="store.state.isGridView"
      :showActionButtons="!store.state.loading.app && !noResultsFound"
      :showSelectAll="showSelectAll"
      :isIndeterminate="isIndeterminate"
      :selectedUsers="selectedUsers"
      :totalResultsCount="totalResultsCount"
      @toggle-view="toggleView"
      @open-filters="toggleFilters"
      @apply-filters="applyFilters"
      @setUsersSelected="setUsersSelected"
      @openCollectionsModal="openCollectionsModal">
    </search-bar>
    <skeletonHomePage v-if="(store.state.loading.app && store.state.isGridView)"></skeletonHomePage>
    <skeletonListView v-else-if="(store.state.loading.app && !store.state.isGridView)"></skeletonListView>
    <div v-else>
      <transition name="slideInFromBottom" >
        <section class="containerResultsPage__content" ref="cardsWrapperRef" :style="minHeightCardsWrapper">
          <section v-if="noResultsFound" class="noResultsMessage--container">
              <no-results-message :mainText="mainText" :text="text" />
          </section>
          <!-- **************************
              Cards Grid/List
          ******************************* -->
          <grid-view v-if="gridViewMode"
            :results="results"
            :selectedUsers="selectedUsers"
            :tooltipActive="tooltipActive"
            :tooltipActiveIndex="tooltipActiveIndex"
            @openProfile="openProfile"
            @updateSelection="updateSelection"
            @showTooltip="showTooltip"
            @hideTooltip="hideTooltip"
            @openCollectionsModal="openCollectionsModal"
            @clickPreviewModal="clickPreviewModal">
          </grid-view>
          <list-view v-if="listViewMode"
            :results="results"
            :selectedUsers="selectedUsers"
            :tooltipActive="tooltipActive"
            :tooltipActiveIndex="tooltipActiveIndex"
            @openProfile="openProfile"
            @showTooltip="showTooltip"
            @updateSelection="updateSelection"
            @hideTooltip="hideTooltip"
            @openCollectionsModal="openCollectionsModal"
            @clickPreviewModal="clickPreviewModal">
          </list-view>
        </section>
      </transition>
      <paginator :totalItems="totalResultsCount"
        :key="results.length"
        :data-index="results.length"
        @set-cards-container-min-height="setCardsContainerMinHeight">
      </paginator>
    </div>
    <filterModal v-if="showFilters" @close-filters="toggleFilters()" @apply-filters="applyFilters"></filterModal>
    <addRemoveItemsToCollectionGeneric
      v-if="store.state.collectionsInfo.isCollectionModalOpened"
      @goToNewCollection="forwardToCreateCollectionModal"
      @updateCollectionContainId="updateCollectionContainId($event)"
      :collectionsList="store.state.collectionsMine"
      @close="openCollectionsModal(null, false)"
      :cardsId="store.state.collectionsCardsId">
    </addRemoveItemsToCollectionGeneric>
    <createCollectionModalGeneric
      v-if="createCollectionModalVisible"
      :creatingWithItems="true"
      :cardsId="addCollectionModalID"
      :userLogged="store.state.dataUserLogin.email"
      @cancel-action="backToCollectionModal"
      @close="toggleCreateCollectionsModal(false)">
    </createCollectionModalGeneric>
    <genericModal
      closeIcon="grey-icon-close"
      :title="previewModalData.title"
      :tooltipTitle="previewModalData.title"
      width="668px"
      height="568px"
      ref="previewModal"
      theme= "containerGenericModal--previewModal">
      <containerPreviewModal :profileData="previewModalData"></containerPreviewModal>
    </genericModal>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import NoResultsMessage from '@/components/messages/NoResultsMessage'
import Messages from '@/json/messages.json'
import searchBar from '@/components/searchBar/searchBar'
import gridView from '@/components/cardDetails/gridView/gridView'
import listView from '@/components/cardDetails/listView/listView'
import filterModal from '@/components/filters/filterModal/filterModal'
import skeletonHomePage from '@/components/skeletons/skeletonHomePage/skeletonHomePage'
import skeletonListView from '@/components/skeletons/skeletonListView/skeletonListView'
import store from '@/store/index'
import paginator from '@/components/paginator/Paginator'
import escapeCharacters from '@/utils/escapeCharacters'
import validateMaintenance from '@/utils/validateMaintenance'
import genericModal from '@/components/genericModal/genericModal'
import { computed, onBeforeMount, onBeforeUpdate, onMounted, onUpdated, ref, watch, nextTick, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import containerPreviewModal from '@/components/containerPreviewModal/containerPreviewModal'

export default {
  name: 'resultsPage',
  components: {
    NoResultsMessage,
    searchBar,
    gridView,
    listView,
    filterModal,
    skeletonHomePage,
    skeletonListView,
    paginator,
    genericModal,
    containerPreviewModal
  },
  beforeRouteEnter (to, from, next) {
    if (!from.name || !store.state.results.length) {
      if (to.query.q) {
        let searchTerm = to.query.q
        if (searchTerm.indexOf('%22') !== -1) {
          searchTerm = searchTerm.replace(/%22/g, '')
          store.commit({
            type: 'SET_CORRECTION_NEW_SPELL',
            correctionNewSpell: to.query.s
          })
        }
        store.commit({
          type: 'SET_SEARCH_VALUE',
          searchValue: searchTerm,
          apply: true
        })
      }

      if (to.query.f) {
        store.commit({
          type: 'filters/SET_FILTERS_FROM_URL',
          filtersUrl: to.query.f
        })
      }

      store.commit({
        type: 'SET_PAGINATION',
        size: Number(48),
        page: Number(to.query.p)
      })
    }
    next(vm => {
      if (!from.name || !store.state.results.length) {
        let value = to.query.q
        value = value ? to.query.q : '*'
        value = escapeCharacters(value, 'Search')
        store.dispatch('setQuery', {
          query: value.replace(/['"]+/g, ''),
          filters: store.getters['filters/getQueryFilters']()
        })
        store.dispatch({
          type: 'getSearchApiPeople',
          params: {
            page: store.state.pagination.page,
            ...store.state.query
          }
        }).then(
          (results) => {
            store.dispatch('setTotalResultsCount', results.data['@odata.count'])
            store.commit({
              type: 'SET_RESULTS',
              results: results.data.value
            })
            store.commit({
              type: 'SET_NEW_SPELL',
              newSpell: results.data.correctedSearchTerm ? results.data.correctedSearchTerm : ''
            })
            store.commit({
              type: 'SET_NEW_SPELL_NUMBER',
              newSpellNumber: results.data.correctedResultNumber
            })
            store.commit({
              type: 'SET_ORIGINAL_RESULTS',
              originalResults: results.data.originalHasResults
            })
            store.dispatch('setQuery', {
              query: value.replace(/['"]+/g, ''),
              filters: store.getters['filters/getQueryFilters']()
            })
            store.state.firstTime = false
            store.state.newSearch = true
            store.state.clickSelectAllFirst = false
            if (!store.state.clickSelectAllFirst) {
              store.dispatch('saveIdsUsers')
            }
          },
          (error) => {
            console.error(error)
            validateMaintenance(error)
          }
        )
      }
    })
  },
  setup (props) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const reySays = ref('')
    const error = ref(false)
    const tooltipActive = ref(null)
    const tooltipActiveIndex = ref(-1)
    const tooltipTimeout = ref(null)
    const selectedUsers = ref([])
    const resultsOffset = ref(0)
    const mainText = ref(Messages.noResultsMsg[0].mainTextSearch)
    const text = ref(Messages.noResultsMsg[0].textSearch)
    const showFilters = ref(false)
    const previewModal = ref(null)
    const previewModalData = ref({})
    const addCollectionModalID = ref(null)
    const randomStartPoint = ref(0)
    const createCollectionModalVisible = ref(false)
    const cardsWrapperRef = ref(null)
    const minHeightCardsWrapper = ref(0)

    if (document.referrer) {
      const meta = document.createElement('meta')
      meta.name = 'referrer'
      meta.content = 'no-referrer'
      document.getElementsByTagName('head')[0].appendChild(meta)
      location.reload()
    }

    onBeforeMount(() => {
      store.commit({
        type: 'SET_GRID_VIEW',
        isGridView: window.innerWidth > 487 && window.innerWidth < 845 ? false : store.state.isGridView
      })
    })

    onBeforeUnmount(() => {
      window.onpopstate = () => {}
    })

    onMounted(() => {
      window.onpopstate = function (event) {
        location.reload()
      }
      if (store.state.results.length > 0 && results.value) {
        error.value = false
        getTotalResultsCount()
      } else if (store.state.results.length === undefined || store.state.results.length === 0) {
        reySays.value = 'Looks like nobody matches your profile.'
      } else {
        error.value = true
        reySays.value = 'Use the search field above to find someone.'
      }
      if (router.currentRoute.value.name === 'resultsPage') restoreScrollPosition()
    })

    onBeforeUpdate(() => {
      getTotalResultsCount()
    })

    /**
      * @description reset all flow when user makes a new search
      */
    onUpdated(() => {
      selectedUsers.value = store.state.newSearch ? [] : selectedUsers.value
      store.state.newSearch = false
    })

    /**
     * @description Closes the Preview when there is a change in the cards.
     * @param loadingAppValue New store.state.showData value
     */
    watch(() => store.state.loading.app, (loadingAppValue) => {
      if (!loadingAppValue) {
        restoreScrollPosition()
        nextTick(() => {
          if (results.value) {
            const topOfCardsWrapper = cardsWrapperRef.value.getBoundingClientRect().top
            minHeightCardsWrapper.value = `--minHeight: ${window.innerHeight - topOfCardsWrapper - 201}px`
          }
        })
      }
    })

    /**
      * @description Set cards result
      */
    const results = computed(() => {
      return store.state.results
    })

    /**
      * @description Set total results count
      */
    const totalResultsCount = computed(() => {
      return store.state.totalResultsCount
    })

    /**
      * @description Set indeterminate property for selecAll checkbox
      */
    const isIndeterminate = computed(() => {
      return selectedUsers.value.length > 0 && selectedUsers.value.length !== totalResultsCount.value
    })

    /**
      * @description Check if no results have been found
      */
    const noResultsFound = computed(() => {
      return !store.state.firstTime && !totalResultsCount.value
    })

    /**
     * @description Check if display gridView mode
     */
    const gridViewMode = computed(() => {
      return store.state.loading.app ? false : (store.state.isGridViewListView ? true : store.state.isGridView)
    })

    /**
     * @description Check if display listView mode
     */
    const listViewMode = computed(() => {
      return store.state.loading.app ? false : (store.state.isGridViewListView ? false : !store.state.isGridView)
    })

    /**
     * @description Check if display select all option
     */
    const showSelectAll = computed(() => {
      return results.value.length > 0 && !store.state.firstTime && (store.state.searchKeyword.length > 0 || store.getters['filters/getQueryFilters']().length > 0)
    })

    /**
     * @description Set cards container min height
     */
    function setCardsContainerMinHeight () {
      nextTick(() => {
        if (results.value) {
          const topOfCardsWrapper = cardsWrapperRef.value.getBoundingClientRect().top
          minHeightCardsWrapper.value = `--minHeight: ${window.innerHeight - topOfCardsWrapper - 201}px`
        }
      })
    }

    /**
     * @description Set up scroll position on back from detail page
     */
    function restoreScrollPosition () {
      const appElement = document.querySelector('#containerApp')
      const scrollTopPosition = localStorage.getItem('scrollTop')
      const scrollTopFlag = localStorage.getItem('restoreScrollTop')
      nextTick(() => {
        if (scrollTopPosition && scrollTopFlag) appElement.scrollTop = scrollTopPosition
        localStorage.removeItem('scrollTop')
        localStorage.removeItem('restoreScrollTop')
      })
    }

    /**
     * @description Show tooltip in cards
     */
    function showTooltip (type, e, index) {
      clearTimeout(tooltipTimeout.value)
      tooltipActive.value = type
      tooltipActiveIndex.value = index
    }

    /**
     * @description Hide tooltip in cards
     */
    function hideTooltip () {
      clearTimeout(tooltipTimeout.value)
      tooltipTimeout.value = setTimeout(() => {
        tooltipActive.value = null
        tooltipActiveIndex.value = -1
      }, 250)
    }

    function openProfile (result, index) {
      store.commit({
        type: 'SET_SCROLL_POSITION',
        scrollPosition: document.getElementById('app').scrollTop,
        appHeigth: document.querySelector(store.state.firstTime ? '#containerHome' : '.containerResultsPage').offsetHeight,
        fromDetailView: true
      })

      if (store.state.firstTime) {
        router.push({
          name: 'profile',
          params: { id: result.id, openProfile: true, k: store.state.randomOffset }
        })
      } else {
        const routeConfig = {
          name: 'profile',
          params: {}
        }
        routeConfig.params.id = result.id
        routeConfig.params.openProfile = true
        if (route.query.q) routeConfig.params.q = route.query.q
        if (route.query.f) routeConfig.params.f = route.query.f
        router.push(routeConfig)
      }
    }

    function getTotalResultsCount () {
      const searchLabel = store.state.newSpell ? ` for&nbsp;<span>"${store.state.newSpell}"</span>` : (store.state.searchKeyword ? ` for&nbsp;<span>"${store.state.searchKeyword}"</span>` : '')
      if (totalResultsCount.value === 1) {
        reySays.value = `${totalResultsCount.value} Result${searchLabel}`
      } else if (totalResultsCount.value === 0 || totalResultsCount.value === undefined) {
        reySays.value = `${totalResultsCount.value} Results${searchLabel}`
      } else if (totalResultsCount.value > 1 && totalResultsCount.value !== undefined) {
        reySays.value = `${totalResultsCount.value} Results${searchLabel}`
      }
    }

    function toggleView () {
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: ''
      })

      store.commit({
        type: 'SET_GRID_VIEW',
        isGridView: !store.state.isGridView
      })

      if (store.state.firstTime) {
        router.push({
          name: 'Home',
          query: { p: store.state.pagination.page, k: store.state.randomOffset, gv: store.state.isGridView }
        })
      } else {
        store.commit({
          type: 'SET_ROUTE_CONFIG',
          searchTerm: store.state.searchKeyword,
          pageNumber: store.state.pagination.page
        })
        router.push(store.state.routeConfig)
      }
    }

    /**
     * @description When user go to home view
     */
    function goHome () {
      if (router.currentRoute.value.name !== 'Home') {
        store.state.firstTime = true
        store.commit({ type: 'filters/RESET_FILTERS' })
        store.commit({ type: 'CLEAR_SEARCH' })
        store.commit({
          type: 'SET_CORRECTION_NEW_SPELL',
          correctionNewSpell: ''
        })
        store.commit({
          type: 'SET_NEW_SPELL',
          newSpell: ''
        })
        store.commit({
          type: 'SET_PAGINATION',
          size: Number(48),
          page: Number(1)
        })
        store.commit({
          type: 'SET_SCROLL_POSITION',
          scrollPosition: 0,
          appHeigth: 0,
          fromDetailView: false
        })
        router.push({ name: 'Home' })
      } else {
        store.commit({
          type: 'SET_LOADING_APP',
          loading: true
        })

        randomStartPoint.value = route.query.r ? route.query.k : Math.floor(Math.random() * (2000 - 100 + 1)) + 100

        if (store.state.pagination.page === 1) {
          store.commit({
            type: 'SET_INITIAL_OFFSET',
            initialOffset: randomStartPoint.value
          })
        }
        store.dispatch({
          type: 'getRandomPeople',
          count: 48,
          k: randomStartPoint.value
        }).then(response => {
          store.state.totalResultsCount = response.data['@odata.count']
          store.state.results = response.data.value
          store.state.firstTime = true
          store.state.randomOffset = randomStartPoint.value

          store.commit({
            type: 'SET_PAGINATION',
            size: Number(48),
            page: route.query.r ? Number(route.query.p) : 1
          })
          store.commit({
            type: 'SET_LOADING_APP',
            loading: false
          })

          router.push({
            query: {
              p: route.query.r ? Number(route.query.p) : 1,
              k: store.state.randomOffset,
              gv: store.state.isGridView
            }
          })

          const navigation = store.state.navigation
          const selector = route.query.q || route.query.f ? '.containerResultsPage' : '#containerHome'
          if (navigation.fromDetailView) {
            document.querySelector(selector).style.height = `${navigation.appHeigth}px`
            document.getElementById('app').scrollTop = navigation.scrollPosition
            setTimeout(() => {
              document.querySelector(selector).style.height = 'auto'
            }, 1200)
          }
        }).catch((error) => validateMaintenance(error))
        store.dispatch('clearStore')
      }
    }

    function moveToTop () {
      const appScroll = document.getElementById('app')
      let currentYOffset = appScroll.scrollTop
      const initYOffset = currentYOffset
      const intervalId = setInterval(function () {
        currentYOffset -= initYOffset * 0.1
        appScroll.scrollTop = currentYOffset
        if (appScroll.scrollTop === 0) {
          clearInterval(intervalId)
        }
      }, 20)
    }

    /**
    * @description Open / Close filter modal
    */
    function toggleFilters () {
      store.commit({
        type: 'filters/FILTERS_LOADING',
        isLoading: !showFilters.value
      })
      showFilters.value = !showFilters.value
    }

    /**
      * @description when the user select apply button inside filters container
      */
    async function applyFilters () {
      store.commit({
        type: 'SET_IDS_LOADED',
        idsLoaded: false
      })
      store.commit({
        type: 'SET_LOADING_APP',
        loading: true
      })

      let searchBoxModel = store.state.searchKeyword ? store.state.searchKeyword : '*'
      searchBoxModel = escapeCharacters(searchBoxModel, 'Search')
      store.commit({
        type: 'filters/UPDATE_URL'
      })
      store.dispatch('setQuery', {
        query: searchBoxModel,
        filters: store.getters['filters/getQueryFilters']()
      })

      try {
        store.commit({
          type: 'CLEAR_PROPERTIES'
        })

        store.commit({
          type: 'SET_INITIAL_OFFSET',
          initialOffset: 0
        })
        const results = await store.dispatch({
          type: 'getSearchApiPeople',
          params: {
            ...store.state.query
          }
        })
        store.state.firstTime = false
        store.state.newSearch = true
        store.state.clickSelectAllFirst = false

        store.dispatch('setTotalResultsCount', results.data['@odata.count'] || 0)
        store.commit({
          type: 'SET_RESULTS',
          results: results.data.value
        })

        store.commit({
          type: 'SET_NEW_SPELL',
          newSpell: results.data.correctedSearchTerm ? results.data.correctedSearchTerm : ''
        })

        store.commit({
          type: 'SET_NEW_SPELL_NUMBER',
          newSpellNumber: results.data.correctedResultNumber
        })

        store.commit({
          type: 'SET_ORIGINAL_RESULTS',
          originalResults: results.data.originalHasResults
        })

        store.commit({
          type: 'SET_ROUTE_CONFIG',
          searchTerm: store.state.searchKeyword,
          pageNumber: 1
        })
        router.push(store.state.routeConfig)

        if (!store.state.clickSelectAllFirst) {
          store.dispatch('saveIdsUsers')
        }

        if (store.state.firstTime) {
          store.commit({
            type: 'SET_LOADING_APP',
            loading: false
          })
        }
      } catch (error) {
        validateMaintenance(error)
        console.error('[ERROR] sendSearchRequest ', error)
      }
    }

    /**
      * @description Open collectons modal
      * @param itemsId From card
      * @param flag true or false to know if modal is open or close
      */
    function openCollectionsModal (itemsId, flag) {
      if (itemsId) addCollectionModalID.value = itemsId
      store.commit({
        type: 'SET_COLLECTION_INFO',
        /* eslint-disable */
        itemsId: itemsId ? itemsId : [],
        /* eslint-enable */
        flag: flag
      })
    }

    /**
     * @description Open/Close modal
     * @param flag flag to indicate if open or close
     */
    function toggleCreateCollectionsModal (flag) {
      createCollectionModalVisible.value = flag
    }

    /**
     * @description Go Back to collection Modal and reload collections
     */
    function backToCollectionModal () {
      store.dispatch({
        type: 'getCollections',
        ids: addCollectionModalID.value
      }).then(
        (response) => {
          openCollectionsModal(addCollectionModalID.value, true)
          toggleCreateCollectionsModal(false)
        }
      )
    }

    /**
     * @description Go to Create collectionModal
     */
    function forwardToCreateCollectionModal () {
      openCollectionsModal(null, false)
      toggleCreateCollectionsModal(true)
    }

    /**
      * @description Dispatch clickPreviewModal event
      * @param {data} object Contains information related to the current user
      */
    function clickPreviewModal (data) {
      previewModalData.value = data
      tooglePreviewModal(true)
    }

    /**
     * @description Show or hide preview modal
     * @param flag  Boolean to itdicate if modal is show or hide
     */
    function tooglePreviewModal (flag) {
      if (flag) previewModal.value.open()
      else previewModal.value.close()
    }

    /**
     * @description Set local variable selectedUsers
     */
    function setUsersSelected (users) {
      selectedUsers.value = users
      document.getElementsByClassName('containerMobileMenu__menu')[0].style.bottom = selectedUsers.value.length > 0 ? '68px' : '34px'
    }

    /**
     * @description Updates the array of selected ids, adding or removing new ids.
     * @param {itemId} string id of the item to be added or removed.
     * @param {isSelected} boolean flag to know if the id will be added or removed.
     */
    function updateSelection (itemId, isSelected) {
      if (isSelected) {
        selectedUsers.value.push(itemId)
      } else {
        selectedUsers.value.splice(selectedUsers.value.indexOf(itemId), 1)
      }
      document.getElementsByClassName('containerMobileMenu__menu')[0].style.bottom = selectedUsers.value.length > 0 ? '68px' : '34px'
    }

    /**
     * @description Updates the collection data to check if it contains item id
     * @param collectionIndex index of the collection to update
     */

    function updateCollectionContainId (collectionIndex) {
      store.commit({
        type: 'ADD_REMOVE_ITEM_FROM_COLLECTION',
        positionCollection: collectionIndex
      })
    }

    return {
      store,
      route,
      router,
      resultsOffset,
      mainText,
      text,
      isIndeterminate,
      noResultsFound,
      gridViewMode,
      listViewMode,
      showTooltip,
      hideTooltip,
      openProfile,
      toggleView,
      goHome,
      moveToTop,
      toggleFilters,
      applyFilters,
      openCollectionsModal,
      setUsersSelected,
      reySays,
      results,
      selectedUsers,
      totalResultsCount,
      showFilters,
      tooltipActive,
      tooltipActiveIndex,
      updateSelection,
      clickPreviewModal,
      previewModalData,
      tooglePreviewModal,
      previewModal,
      backToCollectionModal,
      forwardToCreateCollectionModal,
      addCollectionModalID,
      showSelectAll,
      toggleCreateCollectionsModal,
      createCollectionModalVisible,
      updateCollectionContainId,
      cardsWrapperRef,
      minHeightCardsWrapper,
      setCardsContainerMinHeight
    }
  }
}
</script>
