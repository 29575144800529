<template>
  <div class="skeletonListContainer">
    <div class="skeletonListContainer__list">
      <div class="skeletonListContainer__list--item" v-for="index in itemIterations" :key="index">
        <div class="peopleLoadingBox">
          <div class="peopleLoadingBox__avatar"></div>
          <div class="peopleLoadingBox__information">
            <div class="peopleLoadingBox__information--title"></div>
            <div class="peopleLoadingBox__information--paragraphs">
              <div class="paragraph"></div>
            </div>
          </div>
        </div>
        <div class= "textsList">
           <div class= "textsList__text"></div>
           <div class= "textsList__text"></div>
        </div>
        <div class="socialIcons">
          <div class="socialIcons__icon"></div>
          <div class="socialIcons__icon"></div>
          <div class="socialIcons__icon"></div>
        </div>
        <div class="previewBox">
          <div class="previewBox__box"></div>
          <div class="previewBox__box"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'skeletonListView',
  setup () {
    const itemIterations = ref(12)

    return {
      itemIterations
    }
  }
}
</script>
