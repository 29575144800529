<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave">
    <slot/>
  </transition>
</template>

<script>
export default {
  name: 'ExpandAnimation',
  setup (props) {
    /**
     * @description Get the calculated dimensions of the element and hide it
     * @param element to be animated
     */
    function enter (element) {
      const width = getComputedStyle(element).width
      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height
      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      getComputedStyle(element)
      setTimeout(() => {
        element.style.height = height
      })
    }

    /**
     * @description Set element height as auto so it can be dynamic
     * @param element to be animated
     */
    function afterEnter (element) {
      element.style.height = 'auto'
      element.parentElement.parentElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }

    /**
     * @description Set the height of the element as an absolute value so it can be animated
     * @param element to be animated
     */
    function leave (element) {
      const height = getComputedStyle(element).height
      element.style.height = height
      getComputedStyle(element)
      setTimeout(() => {
        element.style.height = 0
      })
    }

    return {
      enter,
      afterEnter,
      leave
    }
  }
}
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>
