<template>
  <section
    @mouseover="over"
    @mouseout="out"
    v-if="tooltipActive"
    class="containerSocialTooltip"
    :class="listView ? 'listView' : 'gridView'">
    <div v-if="tooltipActive === 'phone'">
      <span class="containerSocialTooltip__text" @click="redirectAction($event)">{{ profileData.phone }}</span>
    </div>
    <div v-if="tooltipActive === 'teams'">
      <span class="containerSocialTooltip__text" @click="redirectAction($event)">Open Microsoft Teams</span>
    </div>
    <div v-if="tooltipActive === 'email'">
      <span class="containerSocialTooltip__text" @click="redirectAction($event)">{{ profileData.email }}</span>
    </div>
    <div v-if="tooltipActive === 'linkedin'">
      <span class="containerSocialTooltip__text" @click="redirectAction($event)">LinkedIn Profile</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'socialTooltip',
  props: {
    profileData: {
      type: Object,
      required: true
    },
    tooltipActive: {
      type: String
    },
    listView: {
      type: Boolean,
      default: false
    },
    index: {
      default: -1
    }
  },
  emits: ['mouseover', 'mouseout'],
  setup (props, { emit }) {
    /**
     * @description emit to keep tooltip active when hover on it
     */
    function over () {
      emit('mouseover', props.tooltipActive, null, props.index)
    }

    /**
     * @description emit to close tooltip
     */
    function out () {
      emit('mouseout')
    }

    /**
     * @description Perform a redirect action
     * @param event DOM event
     */
    function redirectAction (event) {
      event.preventDefault()
      event.stopPropagation()
      emit('mouseout')
      switch (props.tooltipActive) {
        case 'phone':
          window.location = `tel:${props.profileData.phone}`
          break
        case 'teams':
          window.open(`https://teams.microsoft.com/l/chat/0/0?users=${props.profileData.email}`, '_blank')
          break
        case 'email':
          window.open('mailto:' + props.profileData.email, '_self')
          break
        case 'linkedin':
          window.open(props.profileData.linkedin, '_blank')
          break
      }
    }

    return {
      over,
      out,
      redirectAction
    }
  }
}
</script>
