<template>
  <section class="containerFilterModal" @click.stop="closeModal()" ref="containerFilterModal">
    <section class="containerFilterModal__modal" @click.stop.prevent="">
      <section class="containerFilterModal__modal--header">
        <span class="clearAllButton" v-if="store.state.filters.temporaryFilters.length" @click="clearAllFilters">Clear all</span>
        <span class="title">Filters</span>
        <span class="grey-icon-close" @click.stop="closeModal()"></span>
      </section>

      <template v-if="!store.state.filters.isLoading">
        <section class="containerFilterModal__modal--mainContent">
          <filterPillList
            :filterList="store.state.filters.temporaryFilters"
            @clearFilter="filterSelectionHandler($event, false)"
            @calculateNumberOfColumns="calculateNumberOfColumns"
            @clearAllFilters="clearAllFilters"></filterPillList
          >
          <section class="mainContent">
            <filterCategories
              :isModalMobile="isModalMobile"
              @filterToggleSelection="filterSelectionHandler"
              ref="filterCategoriesRef"
            >
            </filterCategories>
            <filterList
              v-if="!isModalMobile"
              :category="store.state.filters.categorySelected"
              :isModalMobile="isModalMobile"
              @filterToggleSelection="filterSelectionHandler"
              ref="filterListRef"
            >
            </filterList>
          </section>
        </section>

        <section class="containerFilterModal__modal--footer">
          <button class="discardButton" @click.stop="closeModal">DISCARD</button>
          <button class="applyButton" @click.stop="applyFilters">APPLY</button>
        </section>
      </template>
      <skeletonFilterModal v-else></skeletonFilterModal>
    </section>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import filterPillList from '../filterPillList/filterPillList'
import filterCategories from '../filterCategories/filterCategories'
import filterList from '../filterList/filterList'
import skeletonFilterModal from '../../skeletons/skeletonFilterModal/skeletonFilterModal'
import { onMounted, onUnmounted, ref } from 'vue'

export default {
  name: 'filterModal',
  components: {
    filterPillList,
    filterCategories,
    filterList,
    skeletonFilterModal
  },
  emits: ['close-filters', 'apply-filters'],
  setup (props, { emit }) {
    const store = useStore()
    const isModalMobile = ref(window.innerWidth <= 804)
    const containerFilterModal = ref('')
    const filterListRef = ref(null)
    const filterCategoriesRef = ref(null)

    onMounted(() => {
      resizeSetUp()
      handleResize()
      store.commit({
        type: 'filters/COPY_FILTERS',
        destiny: 'temporaryFilters',
        origin: 'selectedFilters'
      })

      store.dispatch({
        type: 'filters/getFilters',
        detectLoading: true
      }).then(() => {
        calculateNumberOfColumns()
      })

      containerFilterModal.value.style.setProperty('--mobileHeightFilterModal', `${window.innerHeight}px`)
      if (store.state.isMobile) {
        setTimeout(
          () => {
            document.getElementById('app').style.height = '0px'
          }, 1000)
      }
    })

    onUnmounted(() => {
      if (store.state.isMobile) {
        document.getElementById('app').removeAttribute('style')
      }
    })

    /**
     * @description Handler to know the size of the screen
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
      * @description Handles the resize to know if page is on mobile resolution or not and sets modal height.
      */
    function resizeSetUp () {
      isModalMobile.value = window.innerWidth <= 804
      containerFilterModal.value.style.setProperty('--mobileHeightFilterModal', `${window.innerHeight}px`)
    }

    /**
      * @description updates the store to commit the changes on a filter selection.
      * @param Object {filter} filter changed.
      * @param boolean {isSelected} flag to know if the filter has been selected or not.
      */
    function filterSelectionHandler (filter, isSelected) {
      store.commit({
        type: 'filters/TOGGLE_FILTER_SELECTION',
        filter: filter,
        isSelected: isSelected,
        isTemporaryFilter: true
      })

      store.dispatch({
        type: 'filters/getFilters',
        selectedCategory: store.state.filters.categorySelected.name
      }).then(() => {
        calculateNumberOfColumns()
      })
    }

    /**
      * @description Clears all the temporary selected filters.
      */
    function clearAllFilters () {
      store.commit({
        type: 'filters/CLEAR_ALL_FILTERS',
        isTemporaryFilter: true
      })

      store.dispatch({
        type: 'filters/getFilters',
        selectedCategory: 'business_region'
      }).then(() => {
        calculateNumberOfColumns()
      })
    }

    /**
      * @description Closes modal.
      */
    function closeModal () {
      emit('close-filters')
    }

    /**
      * @description Applies selected filters to current search.
      */
    function applyFilters () {
      store.commit({
        type: 'SET_SCROLL_POSITION',
        scrollPosition: 0,
        appHeigth: 0,
        fromDetailView: false
      })

      store.commit({
        type: 'filters/COPY_FILTERS',
        origin: 'temporaryFilters',
        destiny: 'selectedFilters'
      })

      emit('apply-filters')
      closeModal()
    }

    /**
     * @description Calculates the number of columns
     */
    function calculateNumberOfColumns () {
      if (!isModalMobile.value) {
        filterListRef.value.calculateNumberOfColumns()
      } else {
        filterCategoriesRef.value.calculateNumberOfColumns()
      }
    }

    return {
      store,
      filterSelectionHandler,
      clearAllFilters,
      applyFilters,
      isModalMobile,
      closeModal,
      filterListRef,
      filterCategoriesRef,
      containerFilterModal,
      calculateNumberOfColumns
    }
  }
}
</script>
