<template>
  <div class="skeletonHomeContainer">
    <div class="skeletonHomeContainer__grid">
      <div class="skeletonHomeContainer__grid--item" v-for="index in itemIterations" :key="index">
        <div class="peopleLoadingBox">
          <div class="peopleLoadingBox__avatar"></div>
          <div class="peopleLoadingBox__information">
            <div class="peopleLoadingBox__information--title"></div>
            <div class="peopleLoadingBox__information--paragraphs">
              <div class="paragraph"></div>
              <div class="paragraph"></div>
              <div class="paragraph"></div>
            </div>
            <div class="peopleLoadingBox__social">
              <div class="peopleLoadingBox__social--socials">
                <div class="social"></div>
                <div class="social"></div>
                <div class="social"></div>
              </div>
              <div class="peopleLoadingBox__social--preview">
                <div class="preview"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'skeletonHomePage',
  setup () {
    const itemIterations = ref(12)

    return {
      itemIterations
    }
  }
}
</script>
