<template>
  <section class="containerPills" :class="{'inResultsView': inSearchbar}" v-if="areSelectedFilters">
    <ul class="containerPills__pillList">
      <span class="containerPills__pillList--label">Filtered by</span>
      <li v-for="(filter, index) in filterList"
          :key="index"
          :data-index="index"
          class="containerPills__pillList--pill">
        <filterPill :filterName="filter.name" @clearFilter="clearFilter(filter)"></filterPill>
      </li>
      <li class="containerPills__pillList--clearButton">
        <button class="button" @click="clearAllFilters">Clear All</button>
      </li>
    </ul>
  </section>
</template>

<script>
import { computed } from 'vue'
import filterPill from '../filterPill/filterPill'

export default {
  name: 'filterPillList',
  components: {
    filterPill
  },
  props: {
    inSearchbar: {
      type: Boolean,
      default: false
    },
    filterList: {
      type: Array,
      required: true
    }
  },
  emits: ['clearAllFilters', 'clearFilter'],
  setup (props, { emit }) {
    /**
      * @description Indicates if there are filters to show.
      */
    const areSelectedFilters = computed(() => {
      return props.filterList.length > 0
    })

    /**
      * @description Emits an event to remove all selected filters.
      */
    function clearAllFilters () {
      emit('clearAllFilters')
    }

    /**
      * @description Emits an event to remove a given filter from selected filters list.
      * @param Object {deletedFilter} fitler to be deleted.
      */
    function clearFilter (deletedFilter) {
      emit('clearFilter', deletedFilter)
    }

    return {
      areSelectedFilters,
      clearAllFilters,
      clearFilter
    }
  }
}
</script>
