import store from '../store'
/**
 * @description validate the type error and redirect to maintenance page
 * @param error get the error from the request response
 */
const validateMaintenance = (error) => {
  if (error.response && error.response.status) {
    if (error.response.status !== 401 && error.response.status >= 400 && error.response.status < 600) {
      store.commit({ type: 'SHOW_MAINTENANCE_MODE' })
    }
  }
}

export default validateMaintenance
