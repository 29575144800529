<template>
  <article class="containerPreviewModal">
    <header class="containerPreviewModal__header">
      <div class="containerPreviewModal__header--left">
        <figure class="profile">
          <img class="profile__image" :src="profileData.midsize_picture" :alt=profileData.title>
        </figure>
      </div>
      <div class="containerPreviewModal__header--right">
        <div class="userInfo">
          <p class="userInfo__title">{{profileData.persona_title}}</p>
          <p class="userInfo__agency">
            <span>{{formatArray(profileData.agency)}}</span>
          </p>
          <p class="userInfo__country">
            <span>{{formatArray(profileData.country)}}</span>
          </p>
          <div class="userInfo__detail">
            <a class="userInfo__detail--link" @click.stop.prevent="openProfile(profileData.id)">READ MORE  ></a>
          </div>
        </div>
      </div>
    </header>
    <div class="containerPreviewModal__divisor"></div>
    <section class="containerPreviewModal__body">
      <div class="containerPreviewModal__body--info">
        <p class="content">
          <span class="content__description"><span class="content__title">EXPERIENCE AT GREY: </span>
          {{profileData.description ? profileData.description : 'Not specified'}}</span>
        </p>
      </div>
      <div class="containerPreviewModal__body--info">
        <p class="content">
          <span class="content__description"><span class="content__title">INDUSTRY EXPERIENCE: </span>
          {{formatArray(profileData.categories)}}</span>
        </p>
      </div>
      <div class="containerPreviewModal__body--info">
        <p class="content">
          <span>
            <span class="content__title">MANAGER: </span>
            <span class="content__manager" v-if='profileData.employee_manager' @click.stop.prevent="openProfile(profileData.employee_manager_employeecode)">
              {{profileData.employee_manager}}
            </span>
            <span class="content__no__manager" v-else>
              Not specified
            </span>
          </span>
        </p>
      </div>
    </section>
  </article>
</template>

<script>
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { computed } from 'vue'
export default {
  name: 'containerPreviewModal',
  props: {
    profileData: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()

    /**
     * @description Join an array by commas
     * @param array Array to transform
     */
    const formatArray = computed(() => (array) => {
      return array.length ? array.join(', ') : 'Not specified'
    })

    /**
     * @description Open profile page detail
     * @param ecCode ecCode of the current user
     */
    function openProfile (ecCode) {
      if (!ecCode) {
        return
      }
      if (store.state.firstTime) {
        router.push({
          name: 'profile',
          params: { id: ecCode, openProfile: true, k: store.state.randomOffset }
        })
      } else {
        const routeConfig = {
          name: 'profile',
          params: {
            id: ecCode,
            openProfile: true
          }
        }
        if (route.query.q) routeConfig.params.q = route.query.q
        if (route.query.f) routeConfig.params.f = route.query.f
        router.push(routeConfig)
      }
    }

    return {
      openProfile,
      router,
      formatArray
    }
  }
}
</script>
