<template>
  <section class="containerNoResultsMsg">
    <div class="containerNoResultsMsg__ImgContainer">
      <img class="containerNoResultsMsg__ImgContainer--Image" v-show="loadImage" @load="loadImage = true" :src="require(`@/assets/${image}`)" alt="No search results">
      <h1 class="bookFont containerNoResultsMsg__Text" v-if="loadImage">{{mainText}}</h1>
    </div>
  </section>
</template>

<script>

import { ref } from 'vue'
import messagesConfig from '../../json/messages.json'

export default {
  name: 'NoResultsMessage',
  props: {
    mainText: String,
    text: String
  },
  setup (props) {
    const image = messagesConfig.noResultsMsg[0].image
    const loadImage = ref(false)

    return {
      image,
      loadImage
    }
  }
}
</script>

<style src="./NoResultsMessage.scss" lang="scss"></style>
