<template>
  <section v-if="results" class="containerGridView">
    <a v-for="(profile, index) in results" :key="index"
      class="containerGridView__card"
      :class="{'selected' : selectedUsersRef[profile.id]}"
      @click.prevent="openProfile(profile, index)"
      :href="`/profile/${profile.id}`">
      <div class="containerGridView__card--avatar">
        <img :src="thumbnailUrl(profile)" class="image">
        <genericCheckBox
          v-model="selectedUsersRef[profile.id]"
          class="checkbox"
          @change="updateSelection(profile.id, !selectedUsersRef[profile.id])"
          :class="{'displayed': !store.state.isToolbarVertical}">
        </genericCheckBox>
      </div>
      <section class="containerGridView__card--information">
        <optionsCard :id="profile.id" class="threeDots" @openCollectionsModal="openCollectionsModal"/>
        <div class="profileName" style="-webkit-box-orient: vertical;" :title="profile.title">{{ profile.title }}</div>
        <div class="positionAgency">
          <div class="positionAgency__position" style="-webkit-box-orient: vertical;" :title="profile.persona_title">{{ profile.persona_title ? profile.persona_title.toLowerCase() : 'No position' }}</div>
          <div class="positionAgency__agency" style="-webkit-box-orient: vertical;">{{ profile.agency[0] ? profile.agency[0] : 'No agency' }}</div>
        </div>
        <div class="country" style="-webkit-box-orient: vertical;">{{ profile.country[0] ? profile.country[0] : 'No country' }}</div>
        <section class="social">
          <socialInformation
            :profileData="profile"
            :tooltipActive="tooltipActive"
            :tooltipActiveIndex="tooltipActiveIndex"
            :index="index"
            @showTooltip="showTooltip"
            @hideTooltip="hideTooltip"
            @clickPreviewModal="clickPreviewModal"
          >
          </socialInformation>
        </section>
      </section>
    </a>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import optionsCard from '@/components/optionsCard/optionsCard'
import socialInformation from '@/components/socialInformation/socialInformation.vue'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import { computed, reactive, watch } from 'vue'

export default {
  name: 'gridView',
  components: {
    optionsCard,
    socialInformation,
    genericCheckBox
  },
  props: {
    results: {
      type: Array,
      default () {
        return []
      }
    },
    selectedUsers: {
      type: Array,
      default () {
        return []
      }
    },
    tooltipActive: {
      type: String
    },
    tooltipActiveIndex: {
      default () {
        return -1
      }
    }
  },
  emits: ['openCollectionsModal', 'openProfile', 'showTooltip', 'hideTooltip', 'close', 'updateSelection', 'clickPreviewModal'],
  setup (props, { emit }) {
    const store = useStore()
    const selectedUsersRef = reactive(props.results.reduce((obj, result) => {
      obj[result.id] = props.selectedUsers.some(item => item === result.id)
      return obj
    }, {}))

    /**
     * @description Set thumbnail Url
     */
    const thumbnailUrl = computed(() => {
      return profile => profile.thumbnail_url
    })

    /**
      * @description Open the detail page for a specific profile
      */
    function openProfile (result, index) {
      saveScrollPostition()
      emit('openProfile', result, index)
    }

    /**
     * @description Open the detail page in a new window
    */
    function saveScrollPostition () {
      const appElement = document.querySelector('#containerApp')
      localStorage.setItem('scrollTop', appElement.scrollTop)
    }

    /**
      * @description Show the tooltip for a specific card and type
      */
    function showTooltip (type, e, index) {
      emit('showTooltip', type, e, index)
    }

    /**
      * @description Hide a specific tooltip
      */
    function hideTooltip () {
      emit('hideTooltip')
    }

    /**
    * @description Close a specific tooltip
    */
    function close () {
      emit('close')
    }

    /**
      * @description Action perform by the phone icon, according to the device
      * @param profile Infor about the person profile
    */
    function phoneAction (profile) {
      store.state.isMobile ? window.location = `tel:${profile.phone}` : window.open(`https://teams.microsoft.com/l/chat/0/0?users=${profile.email}`, '_blank')
    }

    /**
      * @description Dispatch emit to open add to collections modal
      * @param itemsId From card
      * @param flag true or false to know if modal is open or close
      */
    function openCollectionsModal (itemsId, flag) {
      emit('openCollectionsModal', itemsId, flag)
    }

    /**
      * @description Dispatch emit to open preview modal
      * @param {results} object Contains information related to the current user
      */
    function clickPreviewModal (profileData) {
      emit('clickPreviewModal', profileData)
    }

    /**
     * @description Updates the array of selected ids, adding or removing new ids.
     * @param {itemId} string id of the item to be added or removed.
     * @param {isSelected} boolean flag to know if the id will be added or removed.
     */
    function updateSelection (itemId, isSelected) {
      emit('updateSelection', itemId, isSelected)
    }

    /**
     * @description Updates the object with the id of selected cards.
     * @param {selectedUsers} array with all the selected cards.
     */
    watch(() => props.selectedUsers, (selectedUsers) => {
      for (const key in selectedUsersRef) {
        selectedUsersRef[key] = false
      }

      selectedUsers.forEach(item => {
        selectedUsersRef[item] = true
      })
    })

    return {
      store,
      thumbnailUrl,
      openProfile,
      showTooltip,
      hideTooltip,
      close,
      phoneAction,
      openCollectionsModal,
      selectedUsersRef,
      updateSelection,
      clickPreviewModal
    }
  }
}
</script>
