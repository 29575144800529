<template>
  <section class="containerMisspellText" v-if="showMisspellComponent">
    <div v-if="store.state.newSpell">
      <div>
        Showing results for
        <span @click="makeNewSearch(store.state.newSpell)" class="containerMisspellText__link">
          {{store.state.newSpell}}
        </span>
      </div>
      <div v-if="!store.state.originalResults" class="containerMisspellText__noResults">
          <span>No results for <strong>{{store.state.searchKeyword}}</strong></span>
      </div>
      <div v-else class="containerMisspellText__subText">
        Search instead for
        <span @click="makeNewSearchOriginal(store.state.searchKeyword)" class="containerMisspellText__link">
          {{store.state.searchKeyword}}
        </span>
      </div>
    </div>
    <div class="containerMisspellText__mean" v-if="store.state.correctionNewSpell">
      Did you mean
      <span @click="makeNewSearch(store.state.correctionNewSpell)" class="containerMisspellText__link">
        {{store.state.correctionNewSpell}}
      </span>?
    </div>
  </section>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'misspellLink',
  emits: ['apply-search'],
  setup (props, { emit }) {
    const store = useStore()

    /**
     * @description check if there are results of both terms, original and corrected
     */
    const resultsExist = computed(() => {
      return !(store.state.newSpellNumber === 0 && !store.state.originalResults)
    })

    /**
     * @description check if misspell component should be displayed
     */
    const showMisspellComponent = computed(() => {
      return (store.state.newSpell || store.state.correctionNewSpell) && resultsExist.value
    })

    /**
      * @description Launches a new search.
      * @param searchTerm new search term.
      */
    function makeNewSearch (searchTerm) {
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTerm
      })
      store.commit({
        type: 'SET_CORRECTION_NEW_SPELL',
        correctionNewSpell: ''
      })

      emit('apply-search')
    }

    /**
      * @description Launches a new search with origin word.
      */
    function makeNewSearchOriginal (searchTerm) {
      store.commit({
        type: 'SET_SEARCH_VALUE',
        searchValue: searchTerm
      })
      store.commit({
        type: 'SET_CORRECTION_NEW_SPELL',
        correctionNewSpell: store.state.newSpell
      })
      store.commit({
        type: 'SET_NEW_SPELL',
        newSpell: ''
      })
      emit('apply-search')
    }

    return {
      store,
      makeNewSearchOriginal,
      makeNewSearch,
      showMisspellComponent
    }
  }
}
</script>

<style src="./misspellLink.scss" lang="scss"></style>
