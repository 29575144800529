<template>
  <section class="containerFilterList md-scrollbar" ref="containerFilterList">
    <h1 class="containerFilterList__title">{{category.label}}</h1>
    <section class="containerFilterList__filterSearch" v-if="needsSearch">
      <span class="containerFilterList__filterSearch--input">
        <span class="grey-icon-search"></span>
        <input class="input" type="text" v-model="searchInput" ref="filterSearch" placeholder="Search..." autofocus/>
      </span>
    </section>
    <section class="containerFilterList__containerList" ref="containerFilterValues">
      <ul class="containerFilterList__containerList--list">
        <li v-for="(filter, index) in visibleFilters" :key="filter.name + index" class="filter">
          <genericCheckBox v-model="filter.isSelected" @change="updateFilter($event, filter)" :key="filter.isSelected" :showTick="true">
            <span class="filter__containerLabel">
              <span class="filter__label" :title="filter.name">{{filter.name}}</span>
              <span class="filter__counter">{{filter.count}}</span>
            </span>
          </genericCheckBox>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import genericCheckBox from '../../genericCheckBox/genericCheckBox'

export default {
  name: 'filterList',
  components: {
    genericCheckBox
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    isModalMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['filterToggleSelection'],
  setup (props, { emit }) {
    const searchInput = ref('')
    const containerFilterList = ref(null)
    const containerFilterValues = ref(null)

    onMounted(() => {
      searchInput.value = ''
      calculateNumberOfColumns()
    })

    /**
      * @description Returns the visible filters.
      */
    const visibleFilters = computed(() => {
      return props.category.filters.filter((filter) => {
        if (searchInput.value && needsSearch.value) {
          return filter.name.toLowerCase().includes(searchInput.value.trim().toLowerCase())
        }
        return true
      })
    })

    /**
      * @description Checks if the Category needs a search input.
      */
    const needsSearch = computed(() => {
      return props.category.filters.length >= 10
    })

    /**
      * @description Clears the input on every category change.
      * @param {object} newCategory new selected category.
      * @param {object} oldCategory previously selected category.
      */
    watch(() => props.category, (newCategory, oldCategory) => {
      if (oldCategory.isSelected !== props.category.isSelected) {
        searchInput.value = ''
        containerFilterValues.value.scrollTop = 0
        calculateNumberOfColumns()
      }
    })

    /**
      * @description Recalculates the width of the columns if it is needed.
      */
    watch(() => searchInput.value, () => {
      calculateNumberOfColumns()
    })

    /**
      * @description Emits an event to notify a change in the selection of a filter.
      * @param boolean {isSelected} flag to know if the filter has been selected.
      * @param object {filter} filter changed.
      */
    function updateFilter (isSelected, filter) {
      emit('filterToggleSelection', filter, isSelected)
    }

    /**
     * @description Sets the number of columns that component will have.
     */
    function calculateNumberOfColumns () {
      if (!containerFilterList.value) {
        return
      }
      const containerOfColumns = containerFilterList.value
      if (props.isModalMobile) {
        containerOfColumns.style.setProperty('--numberOfRows', visibleFilters.value.length)
        containerOfColumns.style.setProperty('--columnWidth', '90%')
      } else {
        const numberOfRows = Math.ceil((containerFilterList.value.offsetHeight - 136) / 30)
        nextTick(() => {
          if (visibleFilters.value.length > (numberOfRows * 2)) {
            containerOfColumns.style.setProperty('--numberOfRows', Math.ceil(visibleFilters.value.length / 2))
            containerOfColumns.style.setProperty('--columnWidth', '260px')
          } else if (visibleFilters.value.length > numberOfRows) {
            containerOfColumns.style.setProperty('--numberOfRows', numberOfRows)
            containerOfColumns.style.setProperty('--columnWidth', '260px')
          } else {
            containerOfColumns.style.setProperty('--numberOfRows', visibleFilters.value.length)
            containerOfColumns.style.setProperty('--columnWidth', 'auto')
          }
        })
      }
    }

    return {
      visibleFilters,
      needsSearch,
      containerFilterValues,
      updateFilter,
      searchInput,
      containerFilterList,
      calculateNumberOfColumns
    }
  }
}
</script>
