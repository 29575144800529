<template>
  <section class="containerSocialInformation">
    <social-tooltip
      @mouseover="showTooltip"
      @mouseout="hideTooltip"
      v-if="tooltipActiveIndex === index"
      :tooltipActive="tooltipActive"
      :profileData="profileData"
      :index="index"
      :listView="listView" />
    <ul class="containerSocialInformation__itemList" ref="iconsListRef">
      <li
        @mouseover="showTooltip(isMobile?'phone':'teams', null, index, 0)"
        @mouseout="hideTooltip()"
        @contextmenu.prevent
        class="containerSocialInformation__itemList--item" :class="phoneClass(profileData)">
        <span :class="`grey-icon-${phoneIcon} icon `" @click.prevent="phoneAction($event, profileData, 0)" />
      </li>
      <li
        @mouseover="showTooltip('email', null, index, 1)"
        @mouseout="hideTooltip()"
        @contextmenu.prevent
        class="containerSocialInformation__itemList--item" :class="{'notAvailable': !profileData.email}">
        <span class="grey-icon-icon-email-cards icon" @click.prevent="emailAction($event, profileData, 1)" />
      </li>
      <li
        @mouseover="showTooltip('linkedin', null, index, 2)"
        @mouseout="hideTooltip()"
        @contextmenu.prevent
        class="containerSocialInformation__itemList--item" :class="{'notAvailable': !profileData.linkedin}">
        <span class="grey-icon-linkedin icon" @click.prevent="linkedinAction($event, profileData, 2)" />
      </li>
      <li
        title="Preview"
        v-if="!store.state.isMobile && !listView"
        class="grey-icon-preview containerSocialInformation__itemList--socialPreview"
        @click="clickPreviewModal($event, profileData)"
        @contextmenu.prevent>
      </li>
    </ul>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import socialTooltip from '@/components/socialTooltip/socialTooltip.vue'
import { computed, ref } from 'vue'

export default {
  name: 'socialInformation',
  components: { socialTooltip },
  props: {
    profileData: {
      type: Object,
      required: true
    },
    tooltipActive: {
      type: String
    },
    tooltipActiveIndex: {
      default: -1
    },
    index: {
      default: -1
    },
    listView: {
      type: Boolean,
      default: false
    }
  },
  emits: ['showTooltip', 'hideTooltip', 'clickPreviewModal'],
  setup (props, { emit }) {
    const store = useStore()
    const iconsListRef = ref(null)

    /**
     * @description Show the phone icon on mobile and the Teams icon on desktop
     */
    const phoneIcon = computed(() => {
      return store.state.isMobile ? 'dialPhone' : 'msteams'
    })

    /**
     * @description Set class for phone/teams not available
     */
    const phoneClass = computed(() => (profile) => {
      return store.state.isMobile ? (!profile.phone ? 'notAvailable' : '') : (!profile.email ? 'notAvailable' : '')
    })

    /**
     * @description Checks that the icon clicked has the notAvailable class
     * @param index index of the icon to check
     */
    const notAvailableData = computed(() => (index) => {
      return iconsListRef.value.children[index] && iconsListRef.value.children[index].className && iconsListRef.value.children[index].className.includes('notAvailable')
    })

    /**
     * @description Show the tooltip for a specific card and type
     * @param type type of social information
     * @param e DOM event
     * @param index card index
     * @param iconIndex index of the icon to show tooltip
     */
    function showTooltip (type, e, index, iconIndex) {
      if (notAvailableData.value(iconIndex)) return
      emit('showTooltip', type, e, index)
    }

    /**
     * @description Hide a specific tooltip
     */
    function hideTooltip () {
      emit('hideTooltip')
    }

    /**
     * @description Action perform by the phone icon, according to the device
     * @param even DOM event
     * @param profile Infor about the person profile
     * @param iconIndex index of the icon clicked
     */
    function phoneAction (event, profile, iconIndex) {
      if (notAvailableData.value(iconIndex)) return
      stopEvent(event)
      store.state.isMobile
        ? (window.location = `tel:${profile.phone}`)
        : window.open(`https://teams.microsoft.com/l/chat/0/0?users=${profile.email}`, '_blank')
    }

    /**
     * @description Action perform by the email icon
     * @param even DOM event
     * @param profile Infor about the person profile
     * @param iconIndex index of the icon clicked
     */
    function emailAction (event, profile, iconIndex) {
      if (notAvailableData.value(iconIndex)) return
      stopEvent(event)
      window.open('mailto:' + profile.email, '_self')
    }

    /**
     * @description Action perform by the linkedin icon
     * @param even DOM event
     * @param profile Infor about the person profile
     * @param iconIndex index of the icon clicked
     */
    function linkedinAction (event, profile, iconIndex) {
      if (notAvailableData.value(iconIndex)) return
      stopEvent(event)
      window.open(profile.linkedin, '_blank')
    }

    /**
     * @description Stop event propagation
     * @param even DOM event
     */
    function stopEvent (event) {
      hideTooltip()
      event.preventDefault()
      event.stopPropagation()
    }

    /**
      * @description Dispatch emit to open preview modal
      * @param event DOM event
      * @param {results} object Contains information related to the current user
      */
    function clickPreviewModal (event, profileData) {
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: ''
      })
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
      emit('clickPreviewModal', profileData)
    }

    return {
      store,
      phoneIcon,
      phoneClass,
      showTooltip,
      phoneAction,
      emailAction,
      linkedinAction,
      hideTooltip,
      iconsListRef,
      clickPreviewModal
    }
  }
}
</script>
