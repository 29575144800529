<template>
  <section class="containerGenericModal" :class="theme" v-if="isVisible">
    <section class="containerGenericModal__overlay" @click="close"></section>
    <section class="containerGenericModal__modal" ref="containerGenericModalRef">
      <header class="containerGenericModal__modal--header">
        <span
          :class="icon"
          class="icon"
          v-if="icon"
        >
        </span>
        <span class="title" v-if="title" :title="tooltipTitle">{{title}}</span>
        <span
          class="closeBtn grey-icon-close"
          @click.stop="emitCloseAction"
          v-if="closeIcon"
        >
        </span>
      </header>
      <slot v-bind:close="close"></slot>
    </section>
  </section>
</template>

<script>
import { onUpdated, ref } from 'vue'
export default {
  name: 'genericModal',
  props: {
    title: {
      type: String,
      required: false
    },
    tooltipTitle: {
      type: String,
      required: false,
      default: ''
    },
    closeIcon: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: ''
    },
    width: {
      type: String,
      required: true
    },
    height: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: ''
    },
    emitClose: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close-action'],
  setup (props, { emit }) {
    const containerGenericModalRef = ref(null)
    const isVisible = ref(false)

    onUpdated(() => {
      if (containerGenericModalRef.value) {
        containerGenericModalRef.value.style.setProperty('--width', props.width)
        containerGenericModalRef.value.style.setProperty('--height', props.height)
      }
    })

    /**
     * @description Dispatch emit when user close modal pressing the X.
     */
    function emitCloseAction () {
      emit('close-action')
      if (!props.emitClose) close()
    }

    /**
     * @description Closes the modal.
     */
    function close () {
      isVisible.value = false
    }

    /**
     * @description Opens the modal.
     */
    function open () {
      isVisible.value = true

      if (containerGenericModalRef.value) {
        containerGenericModalRef.value.style.setProperty('--width', props.width)
        containerGenericModalRef.value.style.setProperty('--height', props.height)
      }
    }

    return {
      containerGenericModalRef,
      isVisible,
      close,
      emitCloseAction,
      open
    }
  }
}
</script>
