<template>
  <section v-if="results" class="containerListView">
    <a
      v-for="(result, index) in results"
      :key="index"
      :class="{ 'selected' : selectedUsersRef[result.id] }"
      @click.prevent="openProfile($event, result, index)"
      :href="`/profile/${result.id}`"
    >
      <section v-if="!store.state.isListViewMobile" class="containerListView__card">
        <section class="containerListView__card--first">
          <genericCheckBox
            v-model="selectedUsersRef[result.id]"
            class="checkbox"
            @change="updateSelection(result.id, !selectedUsersRef[result.id])"
            :class="{'displayed': !store.state.isToolbarVertical}">
          </genericCheckBox>
          <img :src="setProfilePicture(result)" :alt="result.title" class="avatar" />
          <div class="nameWrap">
            <div class="nameWrap__name">{{ result.title }}</div>
            <div class="nameWrap__position">{{ result.persona_title ? result.persona_title.toLowerCase() : 'No position' }}</div>
          </div>
        </section>
        <section class="containerListView__card--agency">
          <div class="agencyList">{{ shorterName(result.agency[0] ? result.agency[0] : 'No agency') }}</div>
          <div class="country">{{ shorterName(result.country[0] ? result.country[0] : 'No country') }}</div>
        </section>
        <section class="containerListView__card--social">
          <socialInformation
            :profileData="result"
            :tooltipActive="tooltipActive"
            :tooltipActiveIndex="tooltipActiveIndex"
            :index="index"
            listView
            @showTooltip="showTooltip"
            @hideTooltip="hideTooltip"
          >
          </socialInformation>
        </section>
        <section class="containerListView__card--options">
          <span title="Preview" class="grey-icon-preview previewIcon" @click.stop.prevent="clickPreviewModal(result)" @contextmenu.prevent />
          <optionsCard
            :id="result.id"
            @openCollectionsModal="openCollectionsModal"
          />
        </section>
      </section>
      <section v-else class="containerListView__cardMobile">
        <genericCheckBox
            v-model="selectedUsersRef[result.id]"
            class="containerListView__cardMobile--checkbox"
            @change="updateSelection(result.id, !selectedUsersRef[result.id])"
            :class="{'displayed': !store.state.isToolbarVertical}">
          </genericCheckBox>
        <img
          :src="setProfilePicture(result)"
          :alt="result.title"
          class="containerListView__cardMobile--avatar"
        />
        <section class="containerListView__cardMobile--third">
          <div class="name">{{ result.title }}</div>
          <div class="position">{{ result.persona_title ? result.persona_title.toLowerCase() : 'No position' }}</div>
          <section class="agencyWrap">
            <span class="agencyWrap__agency">{{ shorterName(result.agency[0] ? result.agency[0] : 'No agency') }}</span>
            <span class="agencyWrap__country">{{ shorterName(result.country[0] ? result.country[0] : 'No country') }}</span>
          </section>
          <section class="containerListView__cardMobile--social">
            <socialInformation
              :profileData="result"
              :tooltipActive="tooltipActive"
              :tooltipActiveIndex="tooltipActiveIndex"
              :index="index"
              listView
              @showTooltip="showTooltip"
              @hideTooltip="hideTooltip"
            >
            </socialInformation>
          </section>
        </section>
        <section class="containerListView__cardMobile--preview" v-if="!store.state.isMobile">
          <span title="Preview" class="grey-icon-preview previewIcon" @click.stop="clickPreviewModal(result)" @contextmenu.prevent/>
        </section>
        <optionsCard
          :id="result.id"
          class="containerListView__cardMobile--options"
          @openCollectionsModal="openCollectionsModal"
        />
      </section>
    </a>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import optionsCard from '@/components/optionsCard/optionsCard'
import socialInformation from '@/components/socialInformation/socialInformation.vue'
import genericCheckBox from '@/components/genericCheckBox/genericCheckBox'
import { computed, reactive, watch } from 'vue'

export default {
  name: 'listView',
  components: { optionsCard, socialInformation, genericCheckBox },
  props: {
    results: {
      type: Array,
      default () {
        return []
      }
    },
    selectedUsers: {
      type: Array,
      default () {
        return []
      }
    },
    tooltipActive: {
      type: String
    },
    tooltipActiveIndex: {
      default () {
        return -1
      }
    }
  },
  emits: ['openProfile', 'showTooltip', 'hideTooltip', 'openCollectionsModal', 'updateSelection', 'clickPreviewModal'],
  setup (props, { emit }) {
    const store = useStore()
    const selectedUsersRef = reactive(props.results.reduce((obj, result) => {
      obj[result.id] = props.selectedUsers.some(item => item === result.id)
      return obj
    }, {}))

    /**
     * @description Short names with length > 25
     */
    const shorterName = computed(() => (name) => {
      if (name && name.length > 25) {
        return name.substring(0, 25) + '...'
      }
      return name
    })

    /**
     * @description Open the detail page for a specific profile
     */
    function openProfile (e, result, index) {
      saveScrollPostition()
      if (!e.target.classList.contains('text') && !e.target.classList.contains('Card-social--TooltipText')) {
        emit('openProfile', result, index)
      }
    }

    /**
     * @description Open the detail page in a new window
    */
    function saveScrollPostition () {
      const appElement = document.querySelector('#containerApp')
      localStorage.setItem('scrollTop', appElement.scrollTop)
    }

    /**
     * @description Show the tooltip for a specific card and type
     * @param type type of social information
     * @param index index of the card
     */
    function showTooltip (type, e, index) {
      emit('showTooltip', type, e, index)
    }

    /**
     * @description Hide a specific tooltip
     */
    function hideTooltip () {
      emit('hideTooltip')
    }

    /**
     * @description Dispatch emit to open add to collections modal
     * @param itemsId From card
     * @param flag true or false to know if modal is open or close
     */
    function openCollectionsModal (itemsId, flag) {
      emit('openCollectionsModal', itemsId, flag)
    }

    /**
      * @description Dispatch emit to open preview modal
      * @param {results} object Contains information related to the current user
      */
    function clickPreviewModal (results) {
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: ''
      })
      emit('clickPreviewModal', results)
    }

    /**
     * @description Set profile picture url or set static image
     * @param result card Information
     */
    function setProfilePicture (result) {
      return result.thumbnail_url
    }

    /**
     * @description Updates the array of selected ids, adding or removing new ids.
     * @param {itemId} string id of the item to be added or removed.
     * @param {isSelected} boolean flag to know if the id will be added or removed.
     */
    function updateSelection (itemId, isSelected) {
      emit('updateSelection', itemId, isSelected)
    }

    /**
     * @description Updates the object with the id of selected cards.
     * @param {selectedUsers} array with all the selected cards.
     */
    watch(() => props.selectedUsers, (selectedUsers) => {
      for (const key in selectedUsersRef) {
        selectedUsersRef[key] = false
      }

      selectedUsers.forEach(item => {
        selectedUsersRef[item] = true
      })
    })

    return {
      store,
      shorterName,
      showTooltip,
      openProfile,
      hideTooltip,
      openCollectionsModal,
      setProfilePicture,
      updateSelection,
      selectedUsersRef,
      clickPreviewModal
    }
  }
}
</script>
