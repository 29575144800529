<template>
  <section class="containerOptionsCard" v-click-away="close">
    <span class="grey-icon-show-more containerOptionsCard__showMoreIcon" @click="setThreeDotsMenu($event)" @contextmenu.prevent></span>
    <transition enter-active-class="menuAnimationIn" leave-active-class="menuAnimationOut">
      <section class="containerOptionsCard__containerOptions" v-if="store.state.threeDotsMenuItem === id" @contextmenu.prevent>
        <ul class="containerOptionsCard__containerOptions--options">
          <li v-for="(item, index) in options" :key="index"
            class="item"
            @click.stop.prevent="manageEvent(item.action)"
          >
            <span :class="item.icon" class="item__icon"></span>
            <span class="item__label">{{item.name}}</span>
          </li>
        </ul>
      </section>
    </transition>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import messages from './optionsCard.json'
import { reactive } from 'vue'

export default {
  name: 'optionsCard',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  emits: ['openCollectionsModal'],
  setup (props, { emit }) {
    const store = useStore()
    const options = reactive(messages.options)

    /**
     * @description: Open or close options.
     * @param e DOM element.
     * @event stopPropagation Stop other clicks in this case.
     * @event preventDefault Stop other clicks in this case.
     */
    function setThreeDotsMenu (e) {
      let element = document.querySelector('.containerSearchBar__searchResultsMsg')
      if (!element) {
        element = document.querySelector('.profileTitle')
      }
      element.click()
      e.stopPropagation()
      e.preventDefault()
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: store.state.threeDotsMenuItem === props.id ? '' : props.id
      })
    }

    /**
     * @description Close menu option
     */
    function close (e) {
      if (e && e.type === 'touchstart' && e.target.classList.contains('item__label')) return false
      if (store.state.threeDotsMenuItem === props.id) {
        store.commit({
          type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
          item: ''
        })
      }
    }

    /**
     * @description Calls a method of the current context and passes the event that called it.
     * @param methodName string with the name of the method.
     */
    function manageEvent (methodName) {
      switch (methodName) {
        case 'openModal':
          openModal()
          break
      }
    }

    /**
     * @description Open collections modal throught emit and call service to get list of collections.
     */
    function openModal () {
      close()
      store.dispatch({
        type: 'getCollections',
        ids: [props.id]
      }).then(() => {
        emit('openCollectionsModal', [props.id], true)
      })
    }

    return {
      store,
      options,
      setThreeDotsMenu,
      manageEvent,
      openModal,
      close
    }
  }
}
</script>
