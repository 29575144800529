<template>
  <nav class="containerPaginator bookFont">
    <ul class="containerPaginator__paginator" v-if="pagesToShow.length > 1">
      <li class="containerPaginator__paginator--page">
        <a class="link"
           @click="goToFirstPage"
           :class="{'linkHidden': isActiveFirstPage}">
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
        </a>
      </li>
    <li class="containerPaginator__paginator--page">
      <a class="link"
        @click="goToPreviousPage"
        :class="{'linkHidden': isActiveFirstPage}">
        <span class="grey-icon-arrow-pagination link__arrowLink"></span>
      </a>
    </li>
    <li class="containerPaginator__paginator--page" v-for="pageNumber in pagesToShow" :key="pageNumber">
      <a class="link number"
        @click="goToPage(pageNumber)"
        :class="{'activeLink': pageNumber === store.state.pagination.page}">
        {{pageNumber}}
      </a>
    </li>
    <li class="containerPaginator__paginator--page">
      <a class="link lastLink"
        @click="goToNextPage"
        :class="{'linkHidden': isActiveLastPage}">
        <span class="grey-icon-arrow-pagination link__arrowLink"></span>
      </a>
    </li>
    <li class="containerPaginator__paginator--page">
        <a class="link lastLink"
           @click="goToLastPage"
           :class="{'linkHidden': isActiveLastPage}">
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
          <span class="grey-icon-arrow-pagination link__doubleArrowLink"></span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'paginator',
  props: ['totalItems'],
  emits: ['set-cards-container-min-height'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const pagesToShow = ref([])
    const maxPages = ref(6)

    onMounted(() => {
      resizeSetUp()
      handleResize()
      setPagesToShow()
    })

    /**
    * @description Caluclates the total number of pages to show.
    */
    const totalPages = computed(() => {
      return Math.ceil((props.totalItems - store.state.initialOffset) / store.state.pagination.size)
    })

    /**
    * @description Returns a flag indicating if first page is active.
    */
    const isActiveFirstPage = computed(() => {
      return store.state.pagination.page === 1
    })

    /**
    * @description Returns a flag indicating if last page is active.
    */
    const isActiveLastPage = computed(() => {
      return store.state.pagination.page === totalPages.value
    })

    /**
      * @description Watch to update masPages
      */
    watchEffect(() => maxPages, () => {
      setPagesToShow()
    })

    /**
      * @description Watch to update totalItems
      */
    watchEffect(() => props.totalItems, () => {
      setPagesToShow()
    })

    /**
     * @description Set max pages according to screen size
     */
    function handleResize () {
      window.addEventListener('resize', function (e) {
        resizeSetUp()
      })
    }

    /**
     * @description Setup resize handle to know the size of the screen
     */
    function resizeSetUp () {
      if ((window.innerWidth <= 576) && (window.innerWidth > 0)) {
        maxPages.value = 3
      } else {
        maxPages.value = 6
      }
    }

    /**
    * @description Returns an array of number increase order, from 1 to given total of elements,
    * i.e. given 10 it returns [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    * @param totalItems max number reached inside of array.
    */
    function getNumeredArray (totalItems) {
      return [...Array.from({ length: totalItems }, (v, i) => ++i)]
    }

    /**
    * @description Gets array of pages based on total pages, max number of pages and total of items.
    */
    function getpagesToShow () {
      if (isNaN(totalPages.value) || !totalPages.value || !maxPages.value || totalPages.value === 1) {
        return []
      }

      if (totalPages.value <= maxPages.value) {
        return getNumeredArray(totalPages.value)
      }

      return getCenteredPages()
    }

    /**
    * @description Gets an array of pages where current page is centered.
    */
    function getCenteredPages () {
      if (store.state.pagination.page <= Math.ceil(maxPages.value / 2)) {
        return getNumeredArray(maxPages.value)
      }

      let pages
      if (maxPages.value % 2 === 0) {
        pages = getNumeredArray(store.state.pagination.page + Math.floor(maxPages.value / 2) - 1).slice(-(maxPages.value))
      } else {
        pages = getNumeredArray(store.state.pagination.page + Math.floor(maxPages.value / 2)).slice(-(maxPages.value))
      }

      if (pages[pages.length - 1] <= totalPages.value) {
        return pages
      } else {
        return getNumeredArray(totalPages.value).slice(-(maxPages.value))
      }
    }

    /**
    * @description Sets pages to show on the UI.
    */
    function setPagesToShow () {
      pagesToShow.value = getpagesToShow()
    }

    /**
    * @description Moves page to previous one.
    */
    function goToPreviousPage () {
      goToPage(store.state.pagination.page - 1)
    }

    /**
    * @description Moves page to next one.
    */
    function goToNextPage () {
      goToPage(store.state.pagination.page + 1)
    }

    /**
     * @description Moves page to first one.
     */
    function goToFirstPage () {
      goToPage(1)
    }

    /**
     * @description Moves page to last one.
     */
    function goToLastPage () {
      goToPage(totalPages.value)
    }

    /**
    * @description Moves pagination to any given page.
    * @param pageNumber number of page to move the pagination to.
    */
    async function goToPage (pageNumber) {
      document.getElementById('containerApp').scrollTop = 0
      store.commit({
        type: 'SET_LOADING_APP',
        loading: true
      })
      if (store.state.firstTime) {
        let resultsOffset = 0
        if (store.state.pagination.page > pageNumber) {
          resultsOffset = Number(store.state.randomOffset) - (store.state.pagination.size * (store.state.pagination.page - pageNumber))
        } else {
          resultsOffset = Number(store.state.randomOffset) + (store.state.pagination.size * (pageNumber - store.state.pagination.page))
        }
        store.commit({
          type: 'SET_RANDOM_OFFSET',
          randomOffset: Number(resultsOffset)
        })
      }
      store.commit({
        type: 'SET_PAGINATION',
        size: store.state.pagination.size,
        page: pageNumber
      })
      try {
        let results = {}
        if (store.state.firstTime) {
          results = await store.dispatch({
            type: 'getRandomPeople',
            count: 48,
            k: store.state.randomOffset
          })
        } else {
          results = await store.dispatch({
            type: 'getSearchApiPeople',
            params: {
              page: store.state.pagination.page,
              ...store.state.query
            }
          })
        }
        store.commit({
          type: 'SET_RESULTS',
          results: results.data.value
        })
        store.commit({
          type: 'SET_LOADING_APP',
          loading: false
        })
        if (store.state.firstTime) {
          router.push({
            name: 'Home',
            query: { p: store.state.pagination.page, k: store.state.randomOffset, gv: store.state.isGridView }
          })
        } else {
          store.commit({
            type: 'SET_ROUTE_CONFIG',
            searchTerm: store.state.modelSearchBox,
            pageNumber: pageNumber
          })
          router.push(store.state.routeConfig)
        }
        emit('set-cards-container-min-height')
      } catch (error) {
        console.error('[ERROR] resultsPageLoad ', error)
      }
    }

    return {
      store,
      router,
      isActiveFirstPage,
      isActiveLastPage,
      goToPreviousPage,
      goToNextPage,
      pagesToShow,
      goToPage,
      goToFirstPage,
      goToLastPage
    }
  }
}
</script>
