<template>
  <section class="containerCategoryTab" ref="containerCategoryTab" :class="{'selected': categoryOpened}">
    <section class="containerCategoryTab__header" @click="selectCategoryFilter(category.name)">
      <span class="grey-icon-success" :class="{'visible': category.hasSelectedItems}"></span>
      <span class="containerCategoryTab__header--label">{{category.label}}</span>
      <span class="containerCategoryTab__header--counter">{{category.count}}</span>
      <span v-if="isModalMobile" class="grey-icon-arrow-minimize" :class="{opened: categoryOpened}"></span>
    </section>
    <ExpandAnimation>
      <section class="containerCategoryTab__content" v-if="isModalMobile && categoryOpened">
        <filterList ref="filterListRef" :category="category" @filterToggleSelection="filterSelectionHandler" :isModalMobile="isModalMobile"></filterList>
      </section>
    </ExpandAnimation>
  </section>
</template>

<script>
import { useStore } from 'vuex'
import filterList from '../filterList/filterList'
import ExpandAnimation from '../../animations/ExpandAnimation'
import { ref, watch } from 'vue'

export default {
  name: 'filterCategoryTab',
  components: {
    filterList,
    ExpandAnimation
  },
  props: {
    category: {
      required: true,
      type: Object
    },
    isModalMobile: {
      type: Boolean,
      default: false
    }
  },
  emits: ['filterToggleSelection'],
  setup (props, { emit }) {
    const store = useStore()
    const categoryOpened = ref(props.category.isSelected)
    const filterListRef = ref(null)

    /**
      * @description Sets category as closed if the category is not selected.
      * @param boolean {isSelected} flag to know if the category has been selected.
      */
    watch(() => props.category.isSelected, (isSelected) => {
      if (!isSelected) {
        categoryOpened.value = false
      }
    })

    /**
      * @description Emits an event to notify a change in the selection of a filter.
      * @param object {filter} filter changed.
      * @param boolean {isSelected} flag to know if the filter has been selected.
      */
    function filterSelectionHandler (filter, isSelected) {
      emit('filterToggleSelection', filter, isSelected)
    }

    /**
      * @description Sets clicked category as the current selected one.
      * @param string {categoryName} name of the category selected.
      */
    function selectCategoryFilter (categoryName) {
      if (!props.category.isSelected) {
        store.commit({
          type: 'filters/SET_SELECTED_CATEGORY',
          categoryName: categoryName
        })
        categoryOpened.value = true
        return
      }

      if (props.isModalMobile) {
        categoryOpened.value = !categoryOpened.value
      }
    }

    /**
     * @description Calculates the number of columns
     */
    function calculateNumberOfColumns () {
      if (props.category.isSelected) filterListRef.value.calculateNumberOfColumns()
    }

    return {
      store,
      categoryOpened,
      selectCategoryFilter,
      filterSelectionHandler,
      filterListRef,
      calculateNumberOfColumns
    }
  }
}
</script>
